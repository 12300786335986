import classNames from "classnames";
import React from "react";
import AssetImage from "../assets/AssetImage";
import { showReportDialog } from "config/SentryConfig";
import { xPath } from "testingClasses";
import { Button, Space } from "@doopage/react-ui-kit";
import { DISABLE_SENTRY } from "../config/Env";
import { useMessengerClient } from "@doopage-client-js/messenger-react";
async function deleteAllDatabases() {
    // Get a list of all databases
    const databases = await indexedDB.databases();

    // Iterate over the list of databases and delete each one
    for (const db of databases) {
        const dbName = db.name;
        if (dbName) {
            const request = indexedDB.deleteDatabase(dbName);
            request.onsuccess = () => {
                console.log(`Database ${dbName} deleted successfully`);
            };
            request.onerror = (event) => {
                console.error(
                    `Error deleting database ${dbName}:`,
                    event.target.error
                );
            };
        }
    }
}
const ErrorComponent = (props) => {
    let { message, error } = props;
    const isRxDBError = error?.message?.includes("RxError");
    const client = useMessengerClient();
    const isLocalHost = window.location.origin.toString().includes("localhost");

    if (!message && error) {
        if (error.name === "Network Error") {
            message = "Vui lòng kiểm tra kết nối mạng";
        }
    }
    if (!message) {
        message = "Có lỗi xảy ra, vui lòng tải lại trang";
    }
    return (
        <div
            className={classNames("flex-center", xPath.stateView.error)}
            style={{ height: "100vh", padding: 20 }}
        >
            <img src={AssetImage.Error} width={"20%"} alt={"img"} />

            <div style={{ marginTop: 50, fontSize: 36, color: "#555" }}>
                {isRxDBError ? "Lỗi dữ liệu" : "Lỗi hệ thống"}
            </div>

            <div
                style={{
                    marginTop: 28,
                    marginBottom: 10,
                    fontSize: 16,
                    color: "#555",
                    fontFamily: "Courier New",
                }}
            >
                {message}
            </div>

            <div
                style={{
                    marginBottom: 28,
                    fontSize: 14,
                    color: "#555",
                    fontFamily: "Courier New",
                    maxWidth: "80%",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    wordBreak: "break-word",
                    background: "#f7f7f7",
                    padding: 10,
                    borderRadius: 4,
                }}
            >
                {error.stack}
            </div>

            <div></div>
            <div className="flex-center-row">
                <Space direction={"horizontal"} spaceWidth={50}>
                    <Button
                        color="info"
                        onClick={() => {
                            window.location.reload();
                        }}
                        margin
                    >
                        TẢI LẠI TRANG
                    </Button>

                    {isRxDBError && (
                        <Button
                            color="danger"
                            onClick={async () => {
                                if (client) {
                                    console.warn("Start clear storage");
                                    await client.clearStorage();
                                    await deleteAllDatabases();
                                    console.warn("Clear storage done");
                                    window.location.reload();
                                }
                            }}
                        >
                            LÀM MỚI DỮ LIỆU
                        </Button>
                    )}

                    {!isLocalHost && !DISABLE_SENTRY && (
                        <Button
                            color="info"
                            onClick={() => {
                                showReportDialog(this.state.eventId);
                            }}
                            simple
                        >
                            BÁO LỖI
                        </Button>
                    )}
                </Space>
            </div>
        </div>
    );
};

export default ErrorComponent;
