import LocalStorage from "config/LocalStorage";
import { sendSocketInbox } from "config/Socket";
import { routesPath } from "router/routesPath";
import DV from "variables/DV";
import { STATE_SOCKET } from "variables/staticValue";
import { CHAT_MODE } from "variables/staticValueMessage";
import Types from "../type";

const findIndexToReplace = (list) => {
    let index = 0;
    for (let i = 0, len = list.length; i < len; i++) {
        if (!list[i]) return i;
        if (
            Date.parse(
                (list[i].last_message && list[i].last_message.created_time) || 0
            ) <
            Date.parse(
                (list[index].last_message &&
                    list[index].last_message.created_time) ||
                    0
            )
        ) {
            index = i;
        }
    }
    return index;
};

const findIndexToHold = (list) => {
    let index = -1;
    for (let i = 0, len = list.length; i < len; i++) {
        if (index === -1 && list[i]) index = i;
        if (
            list[i] &&
            Date.parse(list[i].last_message.created_time) >
                Date.parse(list[index].last_message.created_time)
        ) {
            index = i;
        }
    }
    return index;
};

export const updateRoute = (listInboxSelected) => {
    LocalStorage.setListInboxSelected(listInboxSelected);

    const listId = listInboxSelected.reduce((res, inbox) => {
        if (inbox && inbox.id) res.push(inbox.id);
        return res;
    }, []);

    DV.history &&
        DV.history.push({
            pathname: routesPath.home,
            search: listId.length > 0 ? `i=${listId.join(",")}` : "",
        });
};

export const setListInboxSelected = (listInboxSelected) => {
    return (dispatch) => {
        updateRoute(listInboxSelected);
        dispatch({ type: Types.SELECT_INBOX, listInboxSelected });
    };
};

export const updateInboxSelected = (inbox) => {
    return (dispatch, getState) => {
        if (!inbox) return;

        let listInboxSelected = [...getState().listInboxSelected];

        let index = listInboxSelected.findIndex(
            (item) => item && item.id === inbox.id
        );

        if (index >= 0 && listInboxSelected[index].active) {
            listInboxSelected[index] = {
                ...listInboxSelected[index],
                ...inbox,
            };
            console.log("zxc updateInbox", listInboxSelected);
            dispatch(setListInboxSelected(listInboxSelected));
        }
    };
};

export const clickInbox = (inbox) => {
    return (dispatch, getState) => {
        if (!inbox) return;

        let listInboxSelected = [...getState().listInboxSelected];

        let index = listInboxSelected.findIndex(
            (item) => item && item.id === inbox.id
        );

        if (index >= 0 && listInboxSelected[index].active) return;

        if (index < 0) {
            index = findIndexToReplace(listInboxSelected);
            dispatch(closeInbox(index));
            listInboxSelected = [...getState().listInboxSelected];
            listInboxSelected[index] = {
                ...inbox,
            };
        }

        listInboxSelected = listInboxSelected.map((item) => {
            item && (item.active = item.id === inbox.id);
            return item;
        });
        dispatch(setListInboxSelected(listInboxSelected));
        // dispatch(joinInbox(index));
    };
};

export const activeInbox = (inbox) => {
    if (!inbox) return;
    return (dispatch, getState) => {
        if (!inbox) return;

        let listInboxSelected = [...getState().listInboxSelected];

        let index = listInboxSelected.findIndex(
            (item) => item && item.id === inbox.id
        );

        if (index >= 0 && listInboxSelected[index].active) return;

        listInboxSelected = listInboxSelected.map((item) => {
            item && (item.active = item.id === inbox.id);
            return item;
        });
        dispatch(setListInboxSelected(listInboxSelected));
    };
};

export const dragInbox = (inbox, dragIndex, autoSelect = true) => {
    console.log("drag", inbox);
    return (dispatch, getState) => {
        if (!inbox) return;

        let listInboxSelected = [...getState().listInboxSelected];

        if (
            listInboxSelected[dragIndex] &&
            listInboxSelected[dragIndex].id !== inbox.id
        )
            dispatch(closeInbox(dragIndex));

        listInboxSelected.forEach((item, index) => {
            if (item && item.id === inbox.id) dispatch(closeInbox(index));
        });

        listInboxSelected = [...getState().listInboxSelected];
        listInboxSelected[dragIndex] = {
            ...inbox,
        };

        if (autoSelect)
            listInboxSelected = listInboxSelected.map((item) => {
                item && (item.active = item.id === inbox.id);
                return item;
            });
        console.log("zxc drag Inbox", listInboxSelected);
        dispatch(setListInboxSelected(listInboxSelected));
        // dispatch(joinInbox(dragIndex));
    };
};

export const updateSelectedInbox = (inbox) => {
    return (dispatch, getState) => {
        let listInboxSelected = [...getState().listInboxSelected];
        let index = listInboxSelected.findIndex(
            (item) => item && item.id === inbox.id
        );
        if (index > -1) {
            listInboxSelected[index] = {
                ...listInboxSelected[index],
                ...inbox,
            };

            dispatch(setListInboxSelected(listInboxSelected));
        }
    };
};

export const setMaxInboxOpen = (number) => {
    return (dispatch, getState) => {
        dispatch({ type: Types.SET_MAX_INBOX_OPEN, number });
        dispatch(changeChatMode(getState().chatMode));
    };
};

export const changeChatMode = (chatMode) => {
    return (dispatch, getState) => {
        const { maxInboxOpen } = getState();
        let listInboxSelected = [...getState().listInboxSelected];
        if (chatMode === CHAT_MODE.single) {
            let index = findIndexToHold(listInboxSelected);

            let inbox = listInboxSelected[index] || null;

            // for (let i = 0, len = listInboxSelected.length; i < len; i++) {
            //     if (i !== index && listInboxSelected[i]) {
            //         dispatch(leaveInbox(i));
            //     }
            // }
            listInboxSelected = [inbox];
            console.log("zxc change chatmode", listInboxSelected);
            dispatch(setListInboxSelected(listInboxSelected));
        } else {
            while (maxInboxOpen < listInboxSelected.length) {
                let index = findIndexToReplace(listInboxSelected);
                // if (listInboxSelected[index]) dispatch(leaveInbox(index));
                listInboxSelected.splice(index, 1);
            }

            if (maxInboxOpen > listInboxSelected.length)
                for (
                    let i = 0, len = maxInboxOpen - listInboxSelected.length;
                    i < len;
                    i++
                )
                    listInboxSelected.push(null);

            listInboxSelected.length = maxInboxOpen;
            console.log("zxc change chatmode", listInboxSelected);
            dispatch(setListInboxSelected(listInboxSelected));
        }

        dispatch({ type: Types.SET_CHAT_MODE, chatMode });
    };
};

export const closeAll = () => {
    return (dispatch, getState) => {
        console.log("closeAll");
        let listInboxSelected = [...getState().listInboxSelected];
        listInboxSelected.forEach((item, index) => {
            // dispatch(leaveInbox(index));
            listInboxSelected[index] = null;
        });
        console.log("zxc close all", listInboxSelected);
        dispatch(setListInboxSelected(listInboxSelected));
    };
};

export const joinAll = () => {
    return (dispatch, getState) => {
        console.log("joinAll");
        let listInboxSelected = [...getState().listInboxSelected];
        listInboxSelected.forEach((item, index) => {
            dispatch(joinInbox(index));
        });
    };
};

export const closeInbox = (index) => {
    return (dispatch, getState) => {
        console.log("closeInbox", index);

        let listInboxSelected = [...getState().listInboxSelected];
        if (listInboxSelected[index]) {
            // dispatch(leaveInbox(index));
            listInboxSelected[index] = null;
            console.log("zxc close inbox", listInboxSelected);
            dispatch(setListInboxSelected(listInboxSelected));
        }
    };
};

export const joinInbox = (index) => {
    return (dispatch, getState) => {
        let listInboxSelected = [...getState().listInboxSelected];
        if (
            listInboxSelected[index] &&
            DV.socketInbox &&
            getState().stateConnectChat === STATE_SOCKET.connect
        ) {
            console.log("joinInbox", index, listInboxSelected[index].id);

            sendSocketInbox("JOIN_INBOX", listInboxSelected[index].id);
        }
    };
};

export const leaveInbox = (index) => {
    return (dispatch, getState) => {
        let listInboxSelected = [...getState().listInboxSelected];
        if (listInboxSelected[index]) {
            console.log("leaveInbox", index, listInboxSelected[index].id);
            sendSocketInbox("LEAVE_INBOX", listInboxSelected[index].id);
        }
    };
};

export const setInboxCalling = (data) => {
    return (dispatch, getState) => {
        if (!data) {
            return dispatch({ type: Types.SET_INBOX_CALLING });
        }
        return dispatch({
            type: Types.SET_INBOX_CALLING,
            data,
        });
    };
};

export const addInboxCalling = (inbox) => {
    return (dispatch, getState) => {
        let inboxList = [
            ...getState().inboxCalling,
            {
                ...inbox,
                startTime: new Date(),
            },
        ];
        LocalStorage.setToLocal("inboxCalling", inboxList);
        return dispatch({
            type: Types.ADD_INBOX_CALLING,
            data: {
                ...inbox,
                startTime: new Date(),
            },
        });
    };
};

export const removeInboxCalling = (inbox) => {
    return (dispatch, getState) => {
        LocalStorage.setToLocal(
            "inboxCalling",
            getState().inboxCalling.filter((item) => item.id !== inbox.id)
        );
        return dispatch({
            type: Types.REMOVE_INBOX_CALLING,
            data: inbox,
        });
    };
};

export const addInbox = (inbox) => {
    return (dispatch, getState) => {
        let inboxList = [...getState().inboxList.data];
        const index = inboxList.findIndex((item) => item.id === inbox.id);
        if (index > 0) return;
        const result = [inbox, ...inboxList];
        return dispatch({
            type: Types.SET_INBOX_LIST,
            payload: result,
        });
    };
};

export const closeInboxById = (id) => {
    return (dispatch, getState) => {
        let listInboxSelected = [...getState().listInboxSelected];
        const index = listInboxSelected.findIndex(
            (inbox) => inbox && inbox.id === id
        );
        if (listInboxSelected[index]) {
            // dispatch(leaveInbox(index));
            listInboxSelected[index] = null;
            dispatch(setListInboxSelected(listInboxSelected));
        }
    };
};

export const setCallIsMerged = (state) => {
    return (dispatch, getState) => {
        return dispatch({
            type: Types.SET_CALL_IS_MERGED,
            payload: state,
        });
    };
};

export const showInboxCallError = (inboxId, error) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: Types.HIDE_INBOX_CALL_ERROR,
                data: {
                    inboxId,
                    error,
                },
            });
        }, 4000);
        return dispatch({
            type: Types.SHOW_INBOX_CALL_ERROR,
            data: {
                inboxId,
                error,
            },
        });
    };
};
