import { messengerState } from "@doopage-client-js/messenger";
const { state, valtio } = require("@doopage-client-js/common");

const initalAppState = {
    isShowInboxSource: false,
};

export const appState = state.createStore(initalAppState, {
    name: "CenterApp",
});

export const setAppState = (aState) => {
    messengerState.resetState(appState, aState);
};

export const toggleShowInboxSource = () => {
    appState.isShowInboxSource = !appState.isShowInboxSource;
};

export const useAppState = () => {
    return valtio.useSnapshot(appState);
};

valtio.subscribe(appState, () => {
    console.log("hey hey", appState);
});
