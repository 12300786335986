import { SOCKET } from "config/Env";
import { setLogStyle, useLogStyle } from "config/LogConfig";
import _, { orderBy } from "lodash";
import moment from "moment";
import { joinAll, updateSelectedInbox } from "redux/actionCreator/actionSelectInbox";
import store from "redux/store.js";
import Types from "redux/type";
import { dangerColor, infoColor, successColor } from "variables/color";
import DV from "variables/DV";
import { STATE_SOCKET } from "variables/staticValue";
import { isMyInbox } from "../components/DoopageChat/Message/helpersMessage";
import { dateStringToTimeStamp, getBuildNumber, mergeInbox } from "../helper/helper";

export const closeSocketInbox = () => {
    if (DV.socketInbox) DV.socketInbox.close();
};

let countFailInbox = 0;
let countFailChat = 0;
const timeRetry = 1000;
const multiplier = 2;

const calcRetryTime = (count) => {
    const time = Math.min(multiplier ** count * timeRetry, 10 * 60 * 1000);
    console.log("retry after ", time);
    return time;
};

export const createSocketInbox = () => {
    if (!DV.network || !DV.token || !DV.company || !DV.company.id) return;
    if (
        DV.socketInbox &&
        store.getState().stateConnectInbox === STATE_SOCKET.connect
    )
        return;

    console.log(
        useLogStyle + "SOCKET INBOX: KHỞI TẠO",
        setLogStyle(infoColor),
        countFailInbox,
    );
    const buildNumber = getBuildNumber();
    if(DV.refreshToken) {
        DV.socketInbox = new WebSocket(
            `${SOCKET}/inbox/?jwtoken=${DV.token}&company_id=${DV.company.id}&build=${buildNumber}`,
        );
    } else {
        DV.socketInbox = new WebSocket(
            `${SOCKET}/inbox/?token=${DV.token}&company_id=${DV.company.id}&build=${buildNumber}`,
        );
    }

    store.dispatch({
        type: Types.CONNECT_INBOX,
        payload: STATE_SOCKET.pending,
    });

    DV.socketInbox.onopen = () => {
        countFailInbox = 0;
        console.log(
            useLogStyle + "SOCKET INBOX: KẾT NỐI THÀNH CÔNG",
            setLogStyle(successColor),
        );
        store.dispatch({
            type: Types.CONNECT_INBOX,
            payload: STATE_SOCKET.connect,
        });
        socketInboxAction();

        setTimeout(() => {
            startPingServerInbox();
        }, timePing);
    };

    DV.socketInbox.onclose = () => {
        console.log(
            useLogStyle + "SOCKET INBOX: ĐÓNG SOCKET",
            setLogStyle(dangerColor),
        );
        store.dispatch({
            type: Types.CONNECT_INBOX,
            payload: STATE_SOCKET.close,
        });
        DV.socketInbox = null;
        setTimeout(() => createSocketInbox(), calcRetryTime(countFailInbox));
        countFailInbox++;
    };

    DV.socketInbox.onerror = (event) => {
        console.log("onerror", event);
        store.dispatch({
            type: Types.CONNECT_INBOX,
            payload: STATE_SOCKET.error,
        });
    };
};


var inboxStack = [];
const deltaTime = 0;
var timeOut = null;

const updateData = (data) => {
    console.log("updateData socket", inboxStack);
    inboxStack.forEach((item) => data.unshift(item));
    if (inboxStack.length > 0) {
        data = data.sort((a, b) => {
            const x = new Date(
                a.last_customer_message_time || 0,
            ).getTime();
            const y = new Date(
                b.last_customer_message_time || 0,
            ).getTime();

            return x < y ? 1 : x > y ? -1 : 0;
        });
    }
    inboxStack = [];

    return data;
};

const reloadPanelMessageIfOutdate = (res) => {
    setTimeout(() => {
        let panelRef = DV.panelChatRef.find((panel) => {
            const panelInbox = _.get(panel, "props.inbox", {});
            return (panelInbox.id = res.data.id);
        });
        if (panelRef) {
            let panelInbox = panelRef.props.inbox;
            let resCreatedTime = _.get(res, "data.last_message.created_time");
            let panelLastMessCreatedTime = _.get(
                panelInbox,
                "last_message.created_time",
            );
            if (moment(panelLastMessCreatedTime).isBefore(resCreatedTime)) {
                //panel inbox outdate
                console.log("reload nè", panelRef);
                let reloadMessage = _.get(
                    panelRef,
                    "decoratedRef.current.giftedChat.reloadMessages",
                    () => {
                    },
                );
                reloadMessage();
            }
        }
    }, 5000);
};

const shouldIgnore = (inbox) => {
    if (DV.searching) return true;
    if (!inbox) return true;

    // // if filter by channel then ignore socket from other channel
    // if (!isFilterChannel(inbox?.channel, DV.filterInfo?.channels)) return true;
    //
    // const lastMessageType = inbox?.last_message?.message_type;
    // const {
    //     customer_comment,
    //     reply_comment,
    //     customer_message,
    // } = MAP_MESSAGE_TYPE_DETAIL;
    //
    // // If filter only comment then ignore message
    // if (isFilterOnlyComment(DV.filterInfo)) {
    //     if (lastMessageType === customer_message) return true;
    // }
    //
    // // If filter only message then ignore comment
    // if (isFilterOnlyMessage(DV.filterInfo)) {
    //     if (
    //         lastMessageType === customer_comment ||
    //         lastMessageType === reply_comment
    //     )
    //         return true;
    // }
    //
    // if (DV.filteerInfo?.inbox_state?.includes("not_taked")) {
    //     if (!!inbox?.seller) return true;
    // }
    //
    // if (DV.filterInfo?.inbox_state?.includes("mine")) {
    //     if (!isMyInbox(inbox?.seller)) return true;
    // }
    //
    // if (DV.filterInfo?.tags?.length > 0) {
    //     const filterTags = DV.filterInfo?.tags;
    //     console.log("zxc TAG:", { filterTags, inboxTag: inbox.tags });
    //     const shouldIgnoreInboxTag = !inbox.tags.some((item) =>
    //         filterTags.includes(item?.id)
    //     );
    //     if (shouldIgnoreInboxTag) return true;
    // }
    //
    // if (DV.filterInfo?.segments?.length > 0) {
    //     const filterSegments = DV.filterInfo?.segments;
    //     console.log("zxc SEGMENT:", {
    //         filterSegments,
    //         inboxSegment: inbox.segment,
    //     });
    //     const shouldIgnoreInboxSegment = !filterSegments.includes(
    //         inbox?.segment?.id
    //     );
    //     if (shouldIgnoreInboxSegment) return true;
    // }
    //
    // const isFilteringHavePhoneNumber = DV.filterInfo?.message_state.includes(
    //     "have_phone"
    // );
    // if (isFilteringHavePhoneNumber && !inbox.phone) return true;
    //

    return false;
};

const SOCKET_INBOX_ACTION = {
    PONG: "PONG",
    UPDATE_INBOX: "UPDATE_INBOX",
    PATCH_INBOX: "PATCH_INBOX",
    UPDATE_INBOX_LAST_MESSAGE: "UPDATE_INBOX_LAST_MESSAGE",
    SELLER_INBOX_COUNT: "SELLER_INBOX_COUNT",
};

const updateInboxList = (newInboxes) => {
    store.dispatch({ type: Types.SET_INBOX_LIST, payload: newInboxes });
}

export const socketInboxAction = () => {
    if (!DV?.socketInbox?.readyState) return;

    DV.socketInbox.onmessage = async (message) => {
        let { action, data: resData } = JSON.parse(message?.data ?? "{}");
        const inboxFromSocket = { ...resData,  last_customer_message_timestamp: resData?.last_customer_message_time ?  new Date(resData.last_customer_message_time).getTime() : 0, }

        if (action === SOCKET_INBOX_ACTION.PONG) {
            console.log("PONG SOCKET INBOX ");
            DV.socketInbox.timePong = new Date().getTime();
            return;
        }

        if (!action || !SOCKET_INBOX_ACTION?.[action]) return;

        console.log(
            useLogStyle + "SOCKET INBOX RESPONSE " + action,
            setLogStyle(infoColor),
            { action, inboxFromSocket },
        );
        if (shouldIgnore(inboxFromSocket)) return;

        if (action === SOCKET_INBOX_ACTION.SELLER_INBOX_COUNT) {
            store.dispatch({
                type: Types.SET_SELLER_INBOX_COUNT,
                payload: inboxFromSocket.inbox_count,
            });
            return;
        }


        const { inboxList } = store.getState();
        let data = [...(inboxList?.data ?? [])];
        const inboxIndex = data.findIndex((mess) => mess?.id === inboxFromSocket?.id);
        const needUpdateInbox =
            !!data?.[inboxIndex] &&
            JSON.stringify(data?.[inboxIndex]) !== JSON.stringify(inboxFromSocket);

        if (action === SOCKET_INBOX_ACTION.UPDATE_INBOX) {
            console.log('UPDATE_INBOX:',  {needUpdateInbox,  old: data[inboxIndex], new:inboxFromSocket})
            if (needUpdateInbox) {
                data[inboxIndex] = mergeInbox({...data[inboxIndex], isLocal: true}, inboxFromSocket);
                updateInboxList(data)
                return;
            }
            const isFilterMyInbox =
                DV.filterInfo?.inbox_state?.includes("mine") &&
                isMyInbox(resData?.seller);

            if (!data?.[inboxIndex] && isFilterMyInbox) {
                data.unshift(inboxFromSocket);
                updateInboxList(data)
            }
        }
        if (action === SOCKET_INBOX_ACTION.PATCH_INBOX) {
            if(needUpdateInbox){
                data[inboxIndex] = mergeInbox({...data[inboxIndex], isLocal: true}, inboxFromSocket);
                updateInboxList(data)
            }
            return;
        }

        if (action === SOCKET_INBOX_ACTION.UPDATE_INBOX_LAST_MESSAGE) {
            const currentInbox = data[inboxIndex];
            const socketInbox = inboxFromSocket;
            const currentLastMsgTime = currentInbox?.last_message?.created_time ? dateStringToTimeStamp(currentInbox.last_message.created_time) : 0
            const socketLastMsgTime = socketInbox?.last_message?.created_time ? dateStringToTimeStamp(socketInbox.last_message.created_time) : new Date().getTime()
            if(socketLastMsgTime < currentLastMsgTime || (currentLastMsgTime === socketLastMsgTime && currentInbox?.last_message?.message === socketInbox?.last_message?.message   )) {
                return;
            }
            let updatePosition = true;


            timeOut && clearTimeout(timeOut);

            //đây là inbox ko có trong danh sách inbox
            if (inboxIndex === -1) {
                //Nếu update postion thì đẩy lên đầu
                if (updatePosition) {
                    data = updateData(data);
                    data.unshift(inboxFromSocket);
                    store.dispatch({
                        type: Types.SET_INBOX_LIST,
                        payload: data,
                    });
                } else {
                    //Không thì thêm vào stack, khi nào tới lúc thì đẩy 1 lượt
                    inboxStack.push(inboxFromSocket);
                    timeOut = setTimeout(() => {
                        data = updateData(data);
                       updateInboxList(data)
                    }, deltaTime);
                }
            }

            if (needUpdateInbox) {
                const updatedInbox = mergeInbox({...data[inboxIndex], isLocal: true}, inboxFromSocket);
                //Nếu update postion thì đẩy lên đầu
                if (updatePosition) {
                    data = updateData(data);
                    data.splice(inboxIndex, 1);
                    data.unshift(updatedInbox);
                   updateInboxList(data)
                } else {
                    timeOut = setTimeout(() => {
                        data = updateData(data);
                       updateInboxList(data)
                    }, deltaTime);
                }

                store.dispatch(updateSelectedInbox(updatedInbox));
            }
        }
    };
};

export const sendSocketInbox = (action, data) => {
    if (DV.socketInbox?.readyState === 1)
        DV.socketInbox?.send(
            JSON.stringify({
                command: action,
                inbox: data,
            }),
        );
};

const timePing = 25000;
export const startPingServerInbox = () => {
    // if (isDebug()) return;
    if (!DV.socketInbox || DV.socketInbox.readyState !== "OPEN") return;

    DV.socketInbox.timePong = new Date().getTime();

    DV.socketInbox.send(
        JSON.stringify({
            command: "PING",
        }),
    );

    const interval = setInterval(() => {
        if (DV.socketInbox?.readyState === 1) {
            const waitingTime = DV.socketInbox.timePong
                ? new Date().getTime() - DV.socketInbox.timePong
                : 0;
            console.log("PING SOCKET INBOX ", waitingTime);
            if (waitingTime > timePing * 10) {
                console.warn("PING FAIL, CLOSE SOCKET INBOX", waitingTime);
                clearInterval(interval);
                closeSocketInbox();
            } else {
                if (waitingTime <= timePing * 2)
                    DV.socketInbox.send(
                        JSON.stringify({
                            command: "PING",
                        }),
                    );
            }
        }
    }, timePing);
};

