import { MessengerProvider } from "@doopage-client-js/messenger-react";
import { ThemeProvider } from "@material-ui/core/styles";
import CatchErrorBoundary from "addOnComponent/CatchErrorBoundary";
import {
    SERVER_BASE_URL,
    SOCKET,
    SOCKET_PATH,
    SOCKET_PROTOCOL,
} from "config/Env";
import "config/FirebaseConfig";
import "config/GoogleAnalyticsConfig";
import "config/LogConfig";
import "config/SentryConfig";
import { MuiTheme } from "config/Theme";
import "css/classes.css";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { render } from "react-dom";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import store from "redux/store";
import App2 from "screen/App/App2";
import { unregister } from "./registerServiceWorker";
import LocalStorage from "config/LocalStorage";

const clientOptions = LocalStorage.getMessengerClientOptions();
console.log("options", clientOptions);

window.ResizeObserver = undefined;
render(
    <ThemeProvider theme={MuiTheme}>
        <CatchErrorBoundary showErrorScreen>
            <MessengerProvider clientOptions={clientOptions}>
                <Provider store={store}>
                    <HttpsRedirect>
                        <DndProvider backend={HTML5Backend}>
                            <App2 />
                        </DndProvider>
                    </HttpsRedirect>
                </Provider>
            </MessengerProvider>
        </CatchErrorBoundary>
    </ThemeProvider>,
    document.getElementById("root")
);

unregister();
