import {
    LogLevel,
    forceLogLevel,
    checkAndActivateRemoteDebug,
} from "@doopage-client-js/common";
export const setLogStyle = (color) =>
    `background: ${color}; padding: 2px 5px; color: #fff;`;
export const useLogStyle = "%c";

const removeLog = () => {
    console.log(
        useLogStyle + "Dừng lại!",
        "font-weight: bold; font-size:50px;color:red;"
    );
    console.log(
        useLogStyle +
            "Đây là một tính năng của trình duyệt dành cho các nhà phát triển.",
        "font-weight: bold; font-size:20px;"
    );
    console.log = () => {};
    console.dir = () => {};
};

export const isDebug = () =>
    !process.env.NODE_ENV || process.env.NODE_ENV !== "production";

const enableRemoteDebug = () => {
    let debugData = localStorage.getItem("dooDS");
    console.log("debug session data", debugData);
    if (!debugData) return;
    try {
        debugData = JSON.parse(debugData);
    } catch (e) {
        console.warn("enable debug error", e);
        return;
    }
    const [sessionId, expireAt, ttl] = debugData;
    if (sessionId) {
        if (expireAt > new Date().getTime() / 1000) {
            forceLogLevel(LogLevel.DEBUG);
        }
        checkAndActivateRemoteDebug({
            sessionId,
            expireAt,
            ttl,
            consoleReOptions: {
                redirectDefaultConsoleToRemote: true,
            },
        });
        return true;
    }
};

if (!enableRemoteDebug()) {
    if (isDebug()) console.log("DEBUG MODE");
    else removeLog();
}
