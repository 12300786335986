import { default as decodeJWT } from 'jwt-decode'
import DV from "../variables/DV";
import { handleApi } from "./helper";
import { refreshToken } from "../api/api";
import LocalStorage from "../config/LocalStorage";
import { routesPath } from "../router/routesPath";

export const isRefreshTokenExpired =  () => {
    return new Date().getTime() > DV.refreshTokenExpireTime
}

export const isTokenExpired =  () => {
    return new Date().getTime() > DV.tokenExpireTime
}


export const renewToken = async () => {
    if (isRefreshTokenExpired()) {
        window.location.replace(routesPath.login)
        throw new Error('Token expired')
    }
    let { data, error } = await handleApi(refreshToken());
    if(error) {
        throw error
    }
    DV.token = data.access

    const decodedToken = decodeJWT(data.access)
    const expireTime =  new Date().getTime() + (decodedToken.exp - decodedToken.iat)*1000
    DV.tokenExpireTime = expireTime;
    LocalStorage.setToken(data.access)
    LocalStorage.setToLocal('tokenExpireTime',  expireTime)
}