import React from "react";
import {
    dangerColor,
    infoColor,
    successColor,
    systemMessageColor,
} from "./color";

export const CHAT_MODE = {
    single: "single",
    multi: "multi",
};

export const MAP_MESSAGE_TYPE_DETAIL = {
    customer_comment: 10,
    customer_message: 11,
    seller_message: 20,
    image_message: 21,
    reply_comment: 22,
    review: 23,
    other_seller_message: 30,
    doopage_bot: 80,
    system_internal_seller_message: 81,
    system_order_message: 90,
    system_product_message: 91,
    system_product_image: 92,
    system_confirm_order: 93,
    system_feedback: 94,
    system_auto_reply: 95,
};

export const TITLE_PAGE_MESSAGE = {
    10: "Comment tại: {platform_name}", //customer_comment
    11: "Inbox tại: {platform_name}", //customer_message
    20: "Trả lời tại: {platform_name}",
    21: "Trả lời tại: {platform_name}",
    22: "Trả lời tại: {platform_name}",
    30: "Khách hàng sẽ không nhìn thấy tin nhắn này",
    80: "Doopage Bot", //doopage_bot
    81: "đã đóng tư vấn",
    90: "Hệ thống",
    91: "Hệ thống", //system_order_message
    92: "Hệ thống",
    93: "Xác nhận đơn hàng",
    94: "Phản hồi khách hàng",
    95: "Tin nhắn tự động",
};

export const TITLE_PAGE_MESSAGE_SHOPEE = {
    10: "Đánh giá tại: {platform_name}", //customer_comment
};

export const COLOR_MESSAGE = {
    11: { color: "#1a1a1a", backgroundColor: "#e8e8e8" },
    10: { color: "#1a1a1a", backgroundColor: "#e8e8e8" },
    20: { color: "#ffffff", backgroundColor: infoColor },
    30: { color: "#fff", backgroundColor: successColor },
    80: { color: "#555555", backgroundColor: "#ffffff" },
    81: {
        color: "#1a1a1a",
        backgroundColor: "#ffffff",
        border: `1px solid ${infoColor}`,
    },
    90: { color: "#555555", backgroundColor: systemMessageColor },
    91: { color: "#555555", backgroundColor: systemMessageColor },
    92: { color: "#555555", backgroundColor: systemMessageColor },
    93: { color: "#555555", backgroundColor: systemMessageColor },
    94: { color: "#555555", backgroundColor: systemMessageColor },
    95: { color: "#555555", backgroundColor: systemMessageColor },
};

//todo: loading message mode
export const LOADING_MESSAGE_MODE = {
    error: "error",
    loading: "loading",
    success: "success",
    loading_more: "loading_more",
    loading_search: "loading_search",
    error_more: "error_more",
    end: "end",
};

//todo: send message mode
export const SEND_MESSAGE_MODE = {
    sending: "sending",
    sent: "sent",
    delivered: "delivered",
    error: "error",
    hidden: "hidden",
};

export const MESSAGE_SEND_STATUS = {
    sending: "sending",
    pending: "pending",
    error: "error",
    isSending: (status) => {
        return status === "sending" || status === "pending";
    },
};
