import "../css/Loading.css";
import MainLogo from "components/MainLogo";

const LoadingComponent = () => {
    return (
        <div className="flashit">
            <MainLogo />
        </div>
    );
};

export default LoadingComponent;
