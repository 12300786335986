import * as Sentry from "@sentry/browser";
import classNames from "classnames";
import React, { Component } from "react";
import AssetImage from "../assets/AssetImage";
import { showReportDialog } from "config/SentryConfig";
import { xPath } from "testingClasses";
import PropTypes from "prop-types";
import { Button, Space } from "@doopage/react-ui-kit";
import { routesPath } from "router/routesPath";
import { DISABLE_SENTRY } from "../config/Env";
import ErrorComponent from "./ErrorComponent";
export default class CatchErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, eventId: null };
    }

    componentDidCatch(error, errorInfo) {
        if (error.name === "user is not inital") {
            window.location.href = window.location.origin + routesPath.login;
            return;
        }
        this.setState({ error });
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (!this.state.error) return this.props.children;

        // if (this.props.showErrorScreen)
        //     return (
        //        <ErrorComponent message={this.state.error.message} error={this.state.error} />
        //     );

        return null;
    }
}

CatchErrorBoundary.defaultProps = {
    showErrorScreen: false,
};

CatchErrorBoundary.propTypes = {
    showErrorScreen: PropTypes.bool,
};
