import { APP_VERSION } from "../variables/staticValue";
import { getVersionWithoutBuildNumber } from "../helper/helper";

let pathParent = `/${getVersionWithoutBuildNumber(APP_VERSION)}`;
export const routesPath = {
    login: pathParent + "/login",
    basic_login: pathParent + "/basic-login",
    company: pathParent + "/join-company",
    home: pathParent + "/home",
    order_form: "/order-form",
};
